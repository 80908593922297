
.hide-password {
  opacity: 0;
  transition: opacity 300ms;
}

.field-container {
  display:flex;
  align-items: center;
  justify-content: center;
}

.field {
  height: 55px;
  width: 40vw;
  max-width: 450px;
  min-width:300px;
  padding:5px 110px 5px 20px;
  border-radius: 100px;
  border:none;
  background-color: rgba(0, 0, 0, 0.088);
  /* border: 2px solid rgba(126, 120, 128, 0.47); */
  border-right:0px;
}

.submit-button {
  margin-left:-100px;
  height: 45px;
  padding:5px 20px 5px 20px;
  border-radius: 100px;
  border:none;
  color: white;
  font-weight: 500;
}

.submit-button:hover {
  opacity: 0.8;
  transition: .1s ease-in;
}

.fade {
    animation: fade-in-keyframes 2s;
  }
  @keyframes fade-in-keyframes {
    0% {opacity: 0}
    30% {opacity: 0}
    100% {opacity: 1}
  }