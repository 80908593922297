.App {
  text-align: center;
}

.app-container {
  overflow-x: hidden;
  font-family: 'Rubik';
  /* background-color: #FFF6F5; */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.centered-container {
  width: min(1920px, 90vw);
  max-width: 1920px;
}
