.landing-page-card-mobile-wrapper {
  height: 120vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-page-mobile-final-container {
  position: relative;
  overflow-x: hidden;
  max-height: 100vh;
  max-width: 100vw;
}

.landing-page-card-mobile-wrapper-final {
  height: 100vh;
  width: 100vw;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-page-card-mobile-image {
  max-height: 60vh;
  max-width: 100vw;
}

.mobile-blurb {
  display:flex;
  flex-direction: column;
  position: relative;
  padding-top: 7vh;
  padding-left: 7vh;
  padding-right: 7vh;
  width: 100vw;
  font-family: 'Rubik';
}

.mobile-blurb h1 {
  color: #4b2150
}

.mobile-blurb p {
  margin-top: 10px;
  color: grey;
}


.project-blurb-mobile-rectangle {
  width: calc(21vw + 0px);
  height:5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.top-left-final-image-mobile {
  position:absolute;
  top: 15vh;
  left: -40vw;
  width: 55vw;
  height: auto;
  z-index: -3;
}

.bot-left-final-image-mobile {
  position:absolute;
  top: 20vh;
  left: 20vw;
  width: 55vw;
  height: auto;
  z-index: -2;
}

.top-right-final-image-mobile {
  position:absolute;
  top: 25vh;
  left: 80vw;
  width: 55vw;
  height: auto;
  z-index: -1;
}


#unmasked.landing-page-card-mobile-wrapper{
  background-color: #F8F4FD;
}

#flourish.landing-page-card-mobile-wrapper{
  background-color: #FFFFFF;
}

#campfire.landing-page-card-mobile-wrapper{
  background-color: #F8F4FD;
}