/* router-navbar */
.router-navbar-wrapper {
    width: 100%;
    display:flex;
    padding-top: 60px;
    position: relative;
    z-index: 2;
    align-items: center;
    justify-content: center;
}

.router-navbar-inner-wrapper {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
}

.router-navbar {
    width: 100%;
    display: flex;
    justify-content:flex-end;
    /* center things - not sure if we want this */
    /* align-items: center; */
    margin-top: 0px;
    position: relative;
    z-index: 2;
    gap: 10px;
}

.router-navbar a {
    text-decoration-line: none;
    padding-left: 0px;
    padding-right: 0px;
    letter-spacing: 1px;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: medium;
    opacity: 0.5;
    font-style: italic;

}

.router-navbar a:hover {
    opacity: 1;
}

.router-navbar .active {
    opacity: 1;
}

.logo {
    left: 0;
    align-self: flex-start;
    filter: brightness(0) saturate(100%);
    width: 40px;
    fill: #fff;
}

.logo path {
    fill: #39271C;
    transition: .1s ease-out;
}

.logo path:hover {
    fill: #F9B6B2;
}

/* Default Header Bar Colors */

#def .router-navbar a {
    color: #39271C;
}
#def .logo {
    filter: brightness(100%) saturate(100%);
}

/* Light Colors */

#light .router-navbar a {
    color: #FFFFFF;
}
#light .router-navbar a:hover {
    color: #FFFFFF;
}
#light .router-navbar .active {
    color: #FFFFFF;
}
#light .logo {
    filter:brightness(150%) saturate(0%);
}

/* Dark Colors */

#dark .router-navbar a {
    color: #492a46;
}
#dark .router-navbar a:hover {
    color: #492a46;
}
#dark .router-navbar .active {
    color: #492a46;
}
#dark .logo {
    filter:brightness(30%) saturate(200%);
}