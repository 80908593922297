@import './ScreeningButtonVars.scss';
@import '../../Styles/Functions.scss';
@import '../../Styles/Colors.scss';

.screening-button {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: 50px;
    padding-left:15px;
    padding-right:15px;
    padding-top:10px;
    padding-bottom:10px;
    // padding: $button-padding;
    overflow: hidden;
    text-decoration: none !important;
    z-index: 0;

    .bg {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 100%;
        background-color: var(--bgh);
        z-index: -1;
    }

    .sb-active {
        left: 0% !important;
        opacity: 1.0 !important;
        transition: all 1.0s ease-in-out;
    }

    .text {
        color: var(--t);
        opacity: 80%;
    }

    &:hover .bg {
        animation: sb-enter $anim-dur forwards;
    }

    &:hover a{
        opacity: 100%;
    }

    @keyframes sb-enter {
        from {
            // left: -100%
            left: 0%;
            opacity: 0;
        }
        to {
            left: 0%;
            opacity: 0.3;
        }
    }

    @keyframes sb-exit {
        from {
            // left: 0%;
            left: 100%;
            opacity: 0.3;
        }
        to {
            left: 100%;
            opacity: 0.1;
        }
    }
}

.anim-on .bg {
    animation: sb-exit $anim-dur forwards;
}