/* ------ HELLO CARD ------ */

.hello-page-card-flex-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  /* centers horizontally and limits width */
  margin: 0 auto;
  max-width: 1920px;
}

.hello-page-blurb-flex-wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
  align-items: center;

  /* centers horizontally and limits width */
  margin: 0 auto;
  max-width: 1920px;
}

@keyframes moveIn {
  0% {
    opacity: 0;
    transform: translateX(-10%);
}
  33% {
      opacity: 0;
      transform: translateX(-10%);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.left-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  z-index:3;
  margin-left: 5%;
  max-width:650px;
  margin-top: 0%;
  animation: 1.2s ease-in-out 0s 1 moveIn;
}

.no-decoration {
  color: #6E6871;
  text-decoration: none;
}


.intro-blurb p {
  margin-top:0px;
  margin-bottom:15px;
  color:gray;
  font-size: calc(1vw + 10px);
}

.intro-blurb b {
  font-weight: 500;
  /* color: #D473D1; */
}

.intro-blurb h1 {
  font-family: 'rubik', sans-serif;
  color: #4b2150;
  font-size: calc(3vw + 22px);
  margin:0px;
}

.intro-img {
  margin-left: -5vw;
  flex:1;
  z-index:1;
  animation: 1s ease-in-out 0s 1 fadeIn;
}

.intro-img img {
  width:95vh;
}

.intro-button {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 14px;
  margin-right: 18px;
  margin-top:15px;
  color:white;
  width: calc(20vw);
  min-width:210px;
  background-color:#D473D1;
  transition: .1s ease-out;
  font-size: calc(1vw + 8px);
  text-decoration: none;
}

.intro-button:hover {
  /* background-color: #CE7EFF; */
  opacity: .8;
  transition: .1s ease-in;
  text-decoration: none;
}

.intro-button a {
  font-family:'Rubik', sans-serif;
  font-weight: 700, bold;
  text-decoration: none;
}

.intro-scroll-down p b {
  color:#6E6871;
}

.intro-scroll-down div {
  display: inline-block;
  align-self: flex-end;
}