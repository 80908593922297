@import '../../Styles/Colors.scss';
@import '../../Styles/Fonts.scss';

.about-me-page-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto auto;
  padding-left: 50px;
  padding-right: 50px;
  height: 80vh;
  max-width: 1080px;

    img {
      border-radius:30px;
    }
}

.about-me-page-layout p {
  color: $dark;
}


.about-me-flex-wrapper {
  display: flex;
  flex-direction: row;
}

.about-me-blurb p {
  flex: 1;
  margin-top:0px;
  margin-bottom:15px;
  color: $dark;
  font-size: calc(0.25vw + 12px);
  min-width: 20vw;
}

.about-me-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 490px;
}

@keyframes moveIn {
  0% {
    opacity: 0;
    transform: translateX(10%);
}
  33% {
      opacity: 0;
      transform: translateX(10%);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}


.about-me-blurb {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: 1.2s ease-in-out 0s 1 moveIn;
}

.about-me-rectangle {
  width: 150px;
  height: 5px;
  background-color: $bright-pink;
  margin-top: 20px;
  margin-bottom: 20px;
}


.bullet-blurbs {
  color: $bright-pink;
  font-weight: 600;
  margin-bottom: 2vh;
}

b {
  font-weight: 600;
}

.about-link {
  color: #ed8f8a;
  font-weight: $fwh;
}