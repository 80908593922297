.landing-page-card-wrapper {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.landing-page-card-flex-wrapper {
  display: flex;
}

.landing-page-card-flex-component {
  flex-grow: 1;
  display: flex;
  margin-right: 5vw;
}

.landing-page-final-container {
  position: relative;
  overflow-x: hidden;
  max-height: 100vh;
  max-width: 100vw;
}

.landing-page-card-final {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-page-card-final h2 {
  font-size: calc(3vw + 15px);
}

.landing-page-card-final p {
  margin-top:10px;
  text-align: center;
  margin-bottom: 10px;
  max-width: 80%;
  opacity: 0.6;
  font-size: calc(1vw + 15px);
}

.landing-page-card-final-blurb {
  max-width: 60vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-left-final-image {
  position:absolute;
  top: 10vh;
  left: -5vw;
  width: 25vw;
  height: 35vh;
  z-index: -1;
}

.bot-left-final-image {
  position:absolute;
  bottom: 10vh;
  left: -35vw;
  width: 60vw;
  height: 40vh;
  z-index: -1;
}

.top-right-final-image {
  position:absolute;
  top: 10vh;
  right: -35vh;
  width: 60vw;
  height: 40vh;
  z-index: -1;
}

.bot-right-final-image {
  position:absolute;
  bottom: 10vh;
  right: -15vh;
  width: 30vw;
  height: 40vh;
  z-index: -1;
}