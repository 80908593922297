@import './TranslateButtonVars.scss';
@import '../../Styles/Functions.scss';


.translate-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: 50px;
    overflow: hidden;

    text-decoration: none !important;

    &:not(:hover) {
        background-color: var(--bg);
        color: var(--t);
        transition: $anim-dur;
    }

    &:hover {
        color: var(--th);
        background-color: var(--bgh);
        transition: $anim-dur;
    }
}

.anim-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-left: -$slide-distance;
    margin-right: $slide-distance;

    .section {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .left {
        filter: var(--lif);
        margin-right:15px;
    }

    .right {
        margin-left:15px;
        filter: var(--rif);
    }

    &:hover {
        animation: enter $anim-dur forwards;

        .right {
            opacity:0%;
            transition: 1s ease-out;
        }
    }

    .anim-on &:not(:hover) {
        animation: exit $anim-dur forwards;

        .left {
            opacity:0%;
            transition: 1s ease-out;
        }
    }

    @keyframes enter {
        from {
            margin-left: -$slide-distance;
            margin-right: $slide-distance;
        }
        to {
            margin-left: $slide-distance;
            margin-right: -$slide-distance;
        }
    }

    @keyframes exit {
        from {
            margin-left: $slide-distance;
            margin-right: -$slide-distance;
        }
        to {
            margin-left: -$slide-distance;
            margin-right: $slide-distance;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .normal {
        padding: 22px;
    }
    .small {
        padding: 15px;
    }
}

@media only screen and (max-width: 1280px) {
    .normal {
        padding: 16px;
    }
    .small {
        padding: 12px;
    }
}

@media only screen and (max-width: 768px) {
    .normal {
        padding: 10px;
    }
    .small {
        padding: 6px;
    }
}