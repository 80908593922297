

.sliding-button {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 70px;
    border-style: solid;
    font-weight: 500;
    border-width: 1px;
    transition-duration: 0.4s;
    background-color: var(--normal-bg);
    border-color: var(--border-color);
    overflow: hidden;
}

.st2-anim-bg {
    animation: leave 0.4s forwards;
}

.sliding-button:hover {
    border-color: var(--hovered-border-color);
    transition-duration: 0.4s;
}

@keyframes hover {
    from {
        background-color: var(--normal-bg);
    }
    to {
        background-color: var(--hovered-bg);
    }
}

@keyframes leave {
    from {
        background-color: var(--hovered-bg);
    }
    to {
        background-color: var(--normal-bg);
    }
}


.link:link {
    text-decoration: none !important;
    background-color: none !important;
}

.link:visited  {
    text-decoration: none !important;
    background-color: none !important;
}
.link:hover {
    text-decoration: none !important;
    background-color: none !important;
    animation: hover 0.4s forwards;
}
.link:active {
    text-decoration: none !important;
    background-color: none !important;
}

.sliding-button-text-container {
    position: absolute;
    width: 100%;
    left: 0;
    margin-left: -8%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.st2-anim-translate {
    animation: cont-leave 0.4s forwards;
}

.sliding-button:hover .sliding-button-text-container {
    animation: cont-hover 0.4s forwards;
}

@keyframes cont-hover {
    from {
        margin-left: -8%;
    }
    to {
        margin-left: 8%;
    }
}

@keyframes cont-leave {
    from {
        margin-left: 8%;
    }
    to {
        margin-left: -8%;
    }
}

.middle-text {
    color: var(--normal-text-color);
    fill: var(--normal-text-color);
}

.st2-anim-color {
    animation: middle-leave 0.4s forwards;
}

.sliding-button:hover .middle-text {
    animation: middle-hover 0.4s forwards;
}

@keyframes middle-hover {
    from {
        color: var(--normal-text-color);
        fill: var(--normal-text-color);
    }
    to {
        color: var(--hovered-text-color);
        fill: var(--hovered-text-color);
    }
}

@keyframes middle-leave {
    from {
        color: var(--hovered-text-color);
        fill: var(--hovered-text-color);
    }
    to {
        color: var(--normal-text-color);
        fill: var(--normal-text-color);
    }
}

.left-text {
    display: flex;
    align-items: center;
}

.left-icon {
    padding-right: 20%;
    filter: var(--normal-bg-as-filter);
    width: min(2vw, 30px);
}

.right-text {
    display: flex;
    align-items: center;
}

.right-icon {
    padding-left: 20%;
    filter: var(--normal-text-color-as-filter);
    width: min(2vw, 30px);
}

.default {
    --hovered-bg: #39271C;
    --hovered-text-color: #EBD0CD;
    --normal-bg: #FBE8E6;
    --normal-bg-as-filter: invert(89%) sepia(3%) saturate(1227%) hue-rotate(315deg) brightness(106%) contrast(97%);;
    --normal-text-color: #39271C;
    --normal-text-color-as-filter: invert(11%) sepia(7%) saturate(4391%) hue-rotate(339deg) brightness(92%) contrast(84%);
    --border-color: #39271C;
    --hovered-border-color: #39271C;
}

.dark {
    --hovered-bg: #39271C;
    --hovered-text-color: #EBD0CD;
    --normal-bg: #F9B6B2;
    --normal-bg-as-filter: invert(82%) sepia(5%) saturate(2494%) hue-rotate(314deg) brightness(94%) contrast(107%);;
    --normal-text-color: #39271C;
    --normal-text-color-as-filter: invert(11%) sepia(7%) saturate(4391%) hue-rotate(339deg) brightness(92%) contrast(84%);
    --border-color: #F9B6B2;
    --hovered-border-color: #39271C;
}