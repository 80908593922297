@import '../../Styles/Colors.scss';

.scroll-down {
  cursor: pointer;

  .scroll-down-button {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 1280px) {
  .scroll-down {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 1280px) {
  .scroll-down {
    width: 50px;
    height: 50px;
  }

}

@media only screen and (max-width: 768px) {
  .scroll-down {
    width: 30px;
    height: 30px;
  }
}