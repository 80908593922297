.h1 {
  font-size: calc(3vw + 24px);
  font-family: 'Rubik';
  font-weight: bold;
}

.h2 {
  margin-top: 3vh;
  font-size: calc(1vw + 16px);
  font-family: 'Rubik';
  font-weight: 500;
  margin-bottom: 2vh;
}

.h3 {
  font-size: calc(0.75vw + 16px);
  font-family: 'Rubik';
  font-weight: normal;
}


.p {
  color: #3B333F;
  font-size: calc(0.3vw + 16px);
  font-family: 'Rubik';
  font-weight: lighter;
}

.ul {
  margin-top: -1vh;
}

.li {
  color: #3B333F;
  font-size: calc(0.3vw + 16px);
  font-family: 'Rubik';
  font-weight: lighter;
}

.project-detail-page-layout {
  flex-direction: column;
  /* centers horizontally and limits width */
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30vh;
}

.project-detail-page-banner {
  flex-direction: column;
  margin-top: 5vh;
  height: 50vh;
  margin-bottom: 5vh;
  width: 100%;
  background-size:cover;
  overflow: hidden;
  background-position: center;
  border-radius: 35px;
}


.project-detail-page-banner-mobile {
  flex-direction: column;
  min-height: 25vw;
  max-height: 35vh;
  height: 40vw;
  margin-bottom: 5vh;
}

.project-detail-tag-box {
  color: #787878;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 15px;
  padding-right: 15px;
  min-width: 60px;
  text-align: center;
  font-weight: 700;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 28px;
  background-color: #EEEEEE;
  transition: .2s;
}

.project-detail-tag-box:hover{
  transition: .2s;
  background-color: #d1cad1;
  opacity:.8;
}

.project-detail-tag-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3vh;
  margin-bottom: 2vh;
}

.project-detail-page-full-width-div {
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding: 5vh;
  border-radius: 50px;
}

.project-detail-page-full-width-div-mobile {
  margin-top: 5vh;
  margin-bottom: 5vh;
  border-radius: 50px;
  padding: 5vh;
}

.project-detail-info-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.project-detail-info-box-column {
  margin: 5px;
  padding: 10px;
  flex: 1;
}

/* IMAGE GALLERY */
.gallery-container2 {
	display: flex;
}

.gallery-container {
	display: flex;
}
.gallery-container.gallery-container-margin {
	margin: 2.5px;
}

.gallery-container img {
  transition: .3s;
}

.gallery-column {
  flex: 1;
}

.gallery-container img:hover {
  filter:grayscale(100%);
  transition: .3s;
}

.desc {
  padding: 15px;
  text-align: center;
}