/* ------ GENERAL ------ */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body, div {
  scroll-behavior: smooth;
}

/* ------ LANDING PAGE GENERAL ------ */

.landing-page-card-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.landing-page-card-flex-wrapper {
  display: flex;
  height: 100%;

  /* centers horizontally and limits width */
  margin: 0 auto;
  max-width: 1920px;
}

/* -------- SIDE SCROLLER SELECTOR --------- */
.pagination-additional-class {
  margin: 0;
  position: fixed;
  top: 50%;
  right:0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  transform: translate(0, -50%);
}

.page-item:first-child .page-link {
  margin-left: 0px;
  border-radius: 10px;
  
}

.page-item:last-child .page-link {
  border-radius: 10px;
}

.page-link {
  margin-left: 0px;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: #753f7a;
  padding: 0;
  margin-top: 15px;
  margin-right: 30px;
  border: none;
}

.item-inactive{
  opacity: 0.2;
}

.item-active {
  opacity: 0.9;
}
/* ------ PROJECT CARD CSS --------- */

.project-blurb {
  justify-content:center;
  display:flex;
  flex-direction: column;
  position: relative;
  margin-left:10px;
  padding-left:5%;
  flex:1;
}

.project-blurb p {
  margin-top:10px;
  margin-bottom: 10px;
  max-width: 80%;
  color:gray;
  font-size: calc(1vw + 8px);
}

.project-blurb h2 {
  font-size: calc(3vw + 8px);
  padding-bottom: 10px;
}

.project-blurb h4 {
  margin-top: 10px;
  color: black;
  opacity: 0.6;
  font-size: calc(1.5vw + 8px);
  padding-top: 10px;
}

.project-blurb-rectangle {
  width: calc(7vw + 0px);
  height:5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.project-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.project-button a, .proto-button a {
  font-family:'Rubik', sans-serif;
  font-weight: 600;
  font-size: calc(1vw + 8px);
  letter-spacing: .5px;
  text-decoration: none;
}

.project-button, .proto-button {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 14px;
  margin-right: 18px;
  width: calc(20vw);
  min-width: 220px;
  cursor: pointer;
  font-size: calc(1vw + 8px);
  text-decoration: none;
}

.project-button:hover, .proto-button:hover {
  opacity: 0.8;
  transition: .1s ease-in;
  text-decoration: none;
}

.project-button, .proto-button a {
  text-decoration: none;
  color: #F8F4FD;
}

.project-button, .proto-button {
  text-decoration: none;
}
/*
  =================UNMASKED PROJECT CARD EXAMPLE=================
*/

#unmasked.landing-page-card-wrapper{
  background-color: #F8F4FD;
}

#unmasked a  { color: #F8F4FD; text-decoration: none; }

#unmasked.transition-rectangle {
  position: absolute;
  width: 1vw;
  height: 45vh;
  background: #926BE4;
  top: 70vh;
  left: calc(50vw + 300px);
  z-index: 1;
}

#unmasked b  { color: #926BE4; text-decoration: none; }


/*
  =================FLOURISH PROJECT CARD EXAMPLE=================
*/

#flourish.landing-page-card-wrapper{
  background-color: #FFFFFF;
}


#flourish a  { color: #F8F4FD; text-decoration: none; }

#flourish.transition-rectangle {
  position: absolute;
  width: 1vw;
  height: 45vh;
  background: #8093F1;
  top: 70vh;
  left: calc(50vw - 300px);
  z-index: 1;
}

#flourish b  { color: #8093F1; text-decoration: none; }


/*
  =================PRODUCE PAY PROJECT CARD EXAMPLE=================
*/

#campfire.landing-page-card-wrapper{
  background-color: #F8F4FD;
}

#campfire a  { color: #F84536; text-decoration: none; }

#campfire.transition-rectangle {
  position: absolute;
  width: 1vw;
  height: 45vh;
  background: #F84536;
  top: 70vh;
  left: calc(50vw + 300px);
  z-index: 1;
}

#campfire b { color: #F84536; text-decoration: none; }


/*
  ==================================END==================================
*/

.right {
  flex: 1;
}

.left {
  flex: 1;
}

.blue {
  background-color: blue;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}

.purple {
  background-color: purple;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}

.yellow {
  background-color: yellow;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}

.black {
  background-color: black;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}