@import '../Styles/Colors.scss';
@import '../Styles/Fonts.scss';

.mobile-header-bar {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
}

.router-navbar-burger {
    margin-left: auto;
    align-self: flex-end;
    cursor: pointer;
}

.overlay-cross-container {
    margin-top: 11%;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 9%;
}

.overlay-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.overlay-link {
    color: $bright-pink;
    padding-top: 5vh;
    font-size: 50px;
    font-weight: $fwm;
    opacity: 0.5;
}

.overlay-link:hover, .overlay-link:active {
    color: $dark;
    font-weight: $fwh;
    opacity: 0.5;
    text-decoration: none;
    transition: all 0.2s linear;
}

.active {
    color: $dark;
    font-weight: $fwm;
    opacity: 0.5;
}

.overlay-menu {
    position: absolute;
    left: 0vw;
    top: -100vh;
    background-color: $light-pink;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    transition: top 0.6s ease-out;
}

.show {
    transition: top 0.4s ease-out;
    top: 0vh;
    transition-delay: 0.2s;
}

.overlay-bg {
    position: absolute;
    left: 0vw;
    top: -100vh;
    background-color: $dark;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    opacity: 0.1;
    transition: top 0.6s ease-out;
}

.show-bg {
    transition: all 0.6s ease-out;
    opacity: 0.8;
    top: 0vh;
}


@keyframes om-slide-in {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes om-slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
}