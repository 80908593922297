@import '../../Styles/Colors.scss';
@import '../../Styles/Fonts.scss';

.dark {
    --bg: #{$dark};
    --t: #{$bright-pink};
    --bgh: #{$bright-pink};
    --th: #{$dark};
    --lif: #{$dark-filter};
    --rif: #{$bright-pink-filter};
}

.light-pink {
    --bgh: #{$dark};
    --th: #{$bright-pink};
    --bg: #{$bright-pink};
    --t: #{$dark};
    --rif: #{$dark-filter};
    --lif: #{$bright-pink-filter};
}

.transparent {
    --bgh: #{$dark};
    --th: #{$light-pink};
    --bg: #{$light-pink};
    --t: #{$dark};
    --rif: #{$dark-filter};
    --lif: #{$light-pink-filter};

    &.translate-button {
        border-style: solid;
        border-width: 1px;
        border-color: rgba($dark, 0.4);
    }
}

$slide-distance: 20px;
$anim-dur: 0.4s;