.card-image {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.card-image-container {
  flex: 1;
  width: 100%;
  height: 100%;

}
.card-image-relative-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.top-image {
  z-index: 10;
}
.mid-image {
  z-index: 9;
}
.bot-image {
  z-index: 8;
}

/* ============= PARALLAX SIMULATOR ================ */
@keyframes topslideout {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  30% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes topslidein {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  30% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes midslideout {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  30% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(150%);
  }
}

@keyframes midslidein {
  0% {
    opacity: 0;
    transform: translateY(150%);
  }
  30% {
    opacity: 0;
    transform: translateY(150%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes botslideout {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  30% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-200%);
  }
}

@keyframes botslidein {
  0% {
    opacity: 0;
    transform: translateY(-200%);
  }
  30% {
    opacity: 0;
    transform: translateY(-200%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hidden {
  display: none;
}

.topToggleOut {
  animation: topslideout 1500ms;
  opacity: 0;
}

.topToggleIn {
  animation: topslidein 1500ms;
  opacity: 1;
}

.midToggleOut {
  animation: midslideout 1500ms;
  opacity: 0;
}

.midToggleIn {
  animation: midslidein 1500ms;
  opacity: 1;
}

.botToggleOut {
  animation: botslideout 1500ms;
  opacity: 0;
}

.botToggleIn {
  animation: botslidein 1500ms;
  opacity: 1;
}