.landing-page-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.landing-page-2-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.landing-page-2-project-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}