@import '../../Styles/Colors.scss';
@import '../../Styles/Fonts.scss';

.about-me-page-layout-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  margin: auto auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1080px;

  p {
    color: $dark;
  }

}

.about-me-rectangle-mobile {
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 3px;
  width: 150px;
  height: 1px;
  background-color: $bright-pink;
}

.about-me-page-layout-mobile h4 {
  font-size: 20px;
}