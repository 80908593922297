@import '../../Styles/Functions.scss';
@import '../../Styles/Colors.scss';
@import '../../Styles/Fonts.scss';

.landing-card-container {
    position: relative;

    margin-top: 5vh;
    height: 75vh;
    width: 100%;

    background-color: #281919;
    overflow: hidden;
    border-radius: 35px;
    z-index: 1;

    .non-interactable, .non-interactable {
        pointer-events: none;
        user-select: none;
    }

    .parallax-child {
        position: absolute;
        overflow: hidden;
        z-index: -1;
    }

   
}

.card-center-section {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 3;

    p {
        color: $dusty-pink;
        margin-top:3vh;
        margin-bottom: 2vh;
        max-width: 70%;
        text-align: center;
        font-weight: 400;
        opacity: 60%;
    }

    .button-section {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 34px;


        .button-between-text {
            margin-left: 20px;
            margin-right:10px;
            color: $dusty-pink;
            font-weight: $fwl;
            opacity: 80%;
        }
    }
}

// VERY LARGE SCREEN
@media only screen and (min-width: 1500px) {
    .landing-card-container {
        .star-field {
            width: 90vw;
            max-width: 90vw;
            min-width: 1080px;
        }

        .car {
            width: cvw(31, 1920);
            margin-bottom: -30px;
            margin-left:-20px;
        }

        .telescope-mira {
            margin-right:100px;
            width: cvw(17, 1920);
            margin-bottom: -30px;
        }

        .telescope {
            margin-right: -120px;
            margin-bottom: -80px;
        }
    }

    .card-center-section {
        p {
            max-width: 60%;
        }
        .title-logo {
            width: max(1000px,70%);
            max-width: 40vw;
            min-width: 10vw;
            max-height: 20vh;
            min-height: 10vh;
        }

        .button-section {
            // width: 60%;
            height: 10vw;
            max-height:15%;
            gap: 10px;
        }
    }
}

// MEDIUM SCREEN
@media only screen and (min-width:1200px) and (max-width: 1500px) {
    .landing-card-container {
        .star-field {
            width: 90vw;
            max-width: 90vw;
            min-width: 1080px;
        }

        .car {
            width: cvw(31, 1920);
            margin-bottom: -20px;
            margin-left:-20px;
        }

        .telescope-mira {
            margin-right:90px;
            width: cvw(17, 1920);
            margin-bottom:-20px;
        }

        .telescope {
            margin-right:-70px;
            margin-bottom:-30px;
        }
    }

    .card-center-section {
        .title-logo {
            width: max(1000px,70%);
            max-width: 60vw;
            min-width: 10vw;
            max-height: 11vh;
            min-height: 10vh;
        }

        .button-section {
            // width: 60%;
            height: 10vw;
            max-height:15%;
            gap: 10px;
        }
    }
}

// MEDIUM SMALL SCREEN
@media only screen and (max-width:1200px) and (min-width:900px) {
    .landing-card-container {
        .star-field {
            width: 90vw;
            max-width: 90vw;
            min-width: 1080px;
        }

        .car {
            max-width: cvw(30, 1920);
            margin-bottom:-10px;
        }

        .telescope-mira {
            margin-right:7vw;
            width: 17vw;
            max-width:  cvw(21, 1920);
            margin-bottom: -10px;
        }

        .telescope {
            margin-right:-70px;
            margin-bottom:-30px;
        }
    }

    .card-center-section {
        .title-logo {
            width: max(1000px,70%);
            max-width: 60vw;
            min-width: 10vw;
            max-height: 10vh;
            min-height: 10vh;
        }

        .button-section {
            // width: 60%;
            height: 10vw;
            max-height:15%;
            gap: 10px;
        }
    }
}

// MOBILE OR SMALLER  SCREEN
@media only screen and (max-width: 900px) {
    .landing-card-container {
        height:80vh;



        .star-field {
            width: 90vw;
            max-width: 90vw;
            min-width: 1080px;
        }

        .telescope-mira {
            position: relative;
            top: 15px;
            width: 21vw;
            min-width: 200px;
            margin-bottom:-10px;
        }

        .telescope {
            margin-right:-80px;
            width: 20vw;
            margin-bottom:-70px;
        }
    }

    .card-center-section {
        p {
            max-width: 90%;
        }
        .font-large {
            margin-top: 0px;
            padding-bottom: 6px;
            min-width:350px;
            // font-size: 14px;
        }
        .title-logo {
            margin-top: 40%;
            width: max(800px,70%);
            max-width: 80%;
            // max-width: 70vw;
            min-width: 10vw;
            max-height: 10vh;
            min-height: 10vh;
        }

        .button-section {
            margin-top: 20px;
            flex-direction: column;
            // width: 60%;
            height: 10vw;
            max-height:15%;
            gap: 10px;
        }
    }
}

.mobile-scroll-down-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-telescope-wrapper {
    margin-top: auto;
}