.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 15vh;
  font-size: x-large;
  color: gray;
  font-family: 'Rubik', sans-serif;
  font-size: small;
  font-weight: 500;
}



.footer-container a {
  text-decoration: underline;
  color: gray;
  font-weight: 500;
}