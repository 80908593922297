.landing-page-project-card {
    width: 48.5%;
    padding: 2%;
    margin-top: 3%;
    overflow: hidden;
    background-color: #FDF3F2;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    color: #39271C;
    opacity: 0;
    transform: translateY(20%);
}

.lppc-visible {
    transform: translateY(0%);
    opacity: 1;
    transition: all 1s ease;
}

.lppc-visible:nth-child(2) {
    transition-delay: 200ms;
}

.lppc-visible:nth-child(4) {
    transition-delay: 200ms;
}

.landing-page-project-card-text {
    padding-left: 4%;
    padding-top: 4%;
    font-family: "Rubik", sans-serif;
    color: #39271C;
    align-items: flex-start;
}

.landing-page-project-card b {
    font-weight: 600;
}

.landing-page-project-card h1 {
    font-weight: 400;
    opacity: 0.9;
}

.landing-page-project-card {
    .title {
        opacity: 0.9;
        margin-bottom: 1%;
    }

    .blurb {
        line-height: 1.3;
        padding-right: 15%;
        font-weight: 400;
        opacity: 0.7;
    }
}


.landing-page-project-card-image {
    width: 100%;
    height: 61.5%;
    border-radius: 25px;
}

.read-more-button {
    margin-top: 4%;
    padding-left: 4%;
    padding-bottom: 4%;
}


@media only screen and (max-width: 600px) {
    .landing-page-project-card {
        width: 100%;
    }
}