$dark: #39271C;
$dark-filter: invert(6%) sepia(18%) saturate(1628%) hue-rotate(314deg) brightness(92%) contrast(88%);

$bright-pink: #F9B6B2;
$bright-pink-filter: invert(100%) sepia(45%) saturate(7488%) hue-rotate(290deg) brightness(104%) contrast(95%);

$dusty-pink: #EBD0CD;
$dusty-pink-filter: invert(82%) sepia(26%) saturate(551%) hue-rotate(329deg) brightness(97%) contrast(85%);

$light-pink: #FDF3F2;
$light-pink-filter: invert(91%) sepia(1%) saturate(2059%) hue-rotate(314deg) brightness(108%) contrast(98%);