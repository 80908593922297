/* ------ HELLO CARD ------ */

.hello-page-total-mobile-wrapper {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

.hello-page-card-mobile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro-img-mobile {
  max-height: 50vh;
  max-width: 100vw;
}

@keyframes moveIn {
  0% {
    opacity: 0;
    transform: translateX(-10%);
}
  33% {
      opacity: 0;
      transform: translateX(-10%);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.intro-blurb-mobile {
  display: flex;
  flex-direction: column;
  padding: 7vh;
  padding-top: 0;
  padding-bottom: 2vh;
  align-items: center;
}

.intro-blurb-mobile p {
  color: gray;
  font-size: 18px;
  text-align: center;
}

.intro-blurb-mobile b {
  font-weight: 500;
}

.intro-blurb-mobile h1 {
  color: #4b2150;
}