/* ------ GENERAL ------ */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import '../../Styles/Colors.scss';
@import '../../Styles/Fonts.scss';

.project-page p, a {
    font-family:'Rubik', sans-serif;
    font-weight: 500;
    text-decoration-line: none;
    text-decoration: none;
}

.project-page h2, h1 {
    font-family:'Rubik', sans-serif;
    font-size: 3em;
    font-weight: 600;
    margin: 0px;
}

body, div{
    scroll-behavior: smooth;
}

.project-page {
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100vh;
}

.project-page-container {
    align-self: center;
    width: 100%;
}

/*********************************
  PROJECT SELECTING TAG TEXT STYLE
**********************************/

.project-page-selection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 6vh;
    margin-bottom: 4vh;
    /* margin-left: max(100px, 4vw);
    gap: 5px; */
}

.project-page-selection-button {
    height: 100%;
}

.project-page-selection-text {
    font-weight: 400;
    color: $dark;
    cursor: pointer;
}

.project-page-selection-text:hover {
    color: $dark;
    opacity: 1;
}

.project-page-selection-text.active {
    color: $dark;
    font-weight: bold;
    opacity: 1;
}

/*********************************
    PROJECT GRID SQUARE STYLES
**********************************/

.project-grid-square {
    cursor: pointer;
    overflow: hidden;
    border-radius: 35px;
}

.project-grid-square a {
    text-decoration: none;
}

.project-grid-square > div {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.project-grid-square-foreground {
    display:flex;
    flex-direction: column;
    opacity: 0;
    transition: 0.5s;
    height: 100%;
    animation: 0.6s ease-in-out var(--delay) 1 slidein;
    padding: 10%;
    padding-top: 20%;
}

.project-grid-square-title {
    color: $dark;
}

.project-grid-square-subtitle {
    color: $dark;
    opacity: 0.7;
    font-weight: $fwl;
}

.project-grid-square-tag-section {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 5%;
}

.project-grid-square-tag {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 4%;
    padding-right: 4%;
    background-color: $bright-pink;
    border-radius: 9999px;
    margin-right: 10px;
    margin-bottom: 5px;
}

.project-grid-tag-text {
    color: $dark;
    opacity: 0.8;
}

.project-grid-square-foreground:hover {
    opacity: 1.0;
    box-shadow: inset 0 0 1000px 1000px rgba($light-pink, 0.9);
    transition: 0.3s;
}

.project-grid-square-background {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    animation: 0.6s ease-in-out var(--delay) 1 fadein;
    opacity: 0;
    animation-fill-mode: forwards;
    border-radius: 35px;
}

.project-grid-square-background-img {
    width: 100%;
    height:auto;
    border-radius: 35px;
    margin: 1px;
}

.project-page-grid-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

@keyframes fadein {
    0% {
        transform: translateY(30%);
        opacity: 0;
    }
    33% {
        transform: translateY(30%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes slidein {
    0% {
        transform: translateY(1000%);
    }
    99% {
        transform: translateY(1000%);
    }
    100% {
        transform: translateY(0%);
    }
}
