.landing-page-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #39271C;
    max-width: 1000px;
    /* margin-top: 20px; */
    padding-bottom: 50px;
}
.everything-else {
    text-align: center;
    font-weight: bold;
    margin: 20px;
}

.landing-page-bottom p {
    text-align: center;
    font-weight: 400;
    opacity: 0.7;
    line-height: 1.15;
}

.landing-page-bottom-button {
    font-weight: bold;
    margin: 20px;
}

.landing-page-bottom-image{
    max-width: max(50vw,450px);
    margin-top: -20px;
}