  .scrollTop {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 30px;
    right: 30px;
    padding:18px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    background-color: #DCB5D9;
    color:white;
    opacity: 0.5;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3D(0, 0, 0);
  }

  .scrollTop:hover{
    opacity: 1;
  }
